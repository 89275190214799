import { render, staticRenderFns } from "./XAutocomplete.vue?vue&type=template&id=9f4ba530&"
import script from "./XAutocomplete.vue?vue&type=script&lang=ts&"
export * from "./XAutocomplete.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../tbestpro-management/admin-portal/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../tbestpro-management/admin-portal/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VAutocomplete,VSelect})
