



























import defaultComponent from "../utils/defaultComponent";
import { debounce } from "debounce";

export default defaultComponent.apply({
  props: ["value"],
  data() {
    return {
      staticItems: [],
      willCacheItems: true,
      items: null,
      search: null,
      loading: false,
      debouncedSuggest: debounce(() => {
        this.suggest();
      }, 500)
    };
  },
  watch: {
    async search() {
      if (!this.options.content.itemsSuggestor) {
        return;
      }
      this.debouncedSuggest();
    }
  },
  methods: {
    async initialize() {
      if (this.options.content.itemsLoader) {
        this.items = await this.options.content.itemsLoader(this);
      } else {
        this.items = [];
        const multiple = this.options.attrs.multiple;
        if (this.value && (!multiple || this.value.length)) {
          this.staticItems = await this.options.content.itemsSuggestor(
            "",
            this.value,
            -1,
            this
          );
        }
        this.debouncedSuggest();
      }
    },
    async suggest(willReplace = false) {
      this.loading = true;
      const items = await this.options.content.itemsSuggestor(
        this.search,
        undefined,
        10,
        this
      );
      if (!willReplace) {
        const idField = this.options.attrs["item-value"];
        for (const staticItem of this.staticItems) {
          if (items.every(item => item[idField] !== staticItem[idField])) {
            items.push(staticItem);
          }
        }
      } else {
        this.willCacheItems = false;
      }
      this.items = items;
      this.loading = false;
    }
  },
  extraOptions: {
    defaultClass: "autocomplete"
  }
});
